/*
| ===================================================
| This File is for Card Component
| ===================================================
*/
<template>
  <v-card class="custom-card">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "AppCard"
}
</script>
